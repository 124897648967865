window.frSetup = function frSetup(widgetType, partnerId, externalJobId, options) {
  if (!window.ShadowRoot || !window.postMessage || !window.CSS || !window.CSS.supports('display', 'flex')) {
    reportError(new Error('browser is missing features. Not booting.'));
    return;
  }

  let { ROOT_API, ROOT_LOADER, ROOT_WIDGET, NODE_ENV, VERSION } = process.env;
  let IS_TEST = NODE_ENV === 'test';

  if (widgetType !== 'match') {
    throw new Error('Talent Connect Widget requires widgetType to be set. Allowed values are "match"');
  }
  if (!partnerId) {
    throw new Error('Talent Connect Widget requires PARTNER_ID to be set.');
  }
  if (!externalJobId) {
    throw new Error('Talent Connect Widget requires JOB_ID to be set.');
  }

  let frIframe;
  let frInnerContainer;
  let FAB;
  let memoryUUID;
  let terminology = 'Resume';
  let returningUser = false;

  let isiFrameExpanded = false;
  let forcePopup = false;

  options = options || {};

  options.render = options.render || {};
  options.callbacks = options.callbacks || {};
  
  let allowedKeys = ['candidate', 'render', 'callbacks', 'meta'];
  Object.keys(options).forEach((key) => {
    if (!allowedKeys.includes(key)) {
      let unknownKeyError = new Error('Partner id: ' + partnerId + ' passed an invalid option "'+ key +'". Allowed options keys are:' + allowedKeys.join(', '));
      reportError(unknownKeyError);
    }
  });

  let optionsUsed = {
    candidate: !!options.candidate,
    meta: !!options.meta,
    callback: !!options.callback,
    render: {
      container: !!options.render.container,
      bubble: options.render.bubble,
      popupTiming: options.render.popupTiming,
      autoboot: options.render.autoboot,
      applyLinkTarget: options.render.applyLinkTarget,
      primaryColor: options.render.primaryColor,
      primaryTextColor: options.render.primaryTextColor
    },
    callbacks: {
      onMatchCreated: null,
      onApply: null,
      canBoot: null,
    }
  };


  optionsUsed.callbacks = {
    onMatchCreated: !!options.callbacks.onMatchCreated,
    onApply: !!options.callbacks.onApply,
    canBoot: !!options.callbacks.canBoot
  }

  if (!options.render.container) {
    let container = document.createElement('div');
    container.style.zIndex = '9999999';
    container.style.bottom = '16px';
    container.style.position = 'fixed';

    if (isMobile()) {
      container.style.margin = 'auto 5%';
    } else {
      container.style.right = '16px';
    }

    options.render.container = container;
    document.body.appendChild(options.render.container);
  }

  let styleLink = document.createElement('link');
  styleLink.setAttribute('rel', 'stylesheet');
  styleLink.setAttribute('href', ROOT_LOADER + '/widget.css');

  options.render.container.attachShadow({ mode: 'open' });
  options.render.container.shadowRoot.appendChild(styleLink);
  
  if (options.render.bubble !== true && options.render.bubble !== false && options.render.bubble !== undefined) {
    let missingEmail = new Error('Partner id: ' + partnerId + ' passed an invalid option. options.render.bubble must be one of true, false, undefined');
    reportError(missingEmail);
  }

  if (options.render.popupTiming === undefined) {
    options.render.popupTiming = 5000;
  }

  if (options.render.autoboot && options.render.bubble === false) {
    throw new Error('You cannot pass BOTH render.bubble = false and render.autoboot = true. If you opt to control rendering, you must also start and stop the wiget with boot() and shutdown()');
  }

  if (options.render.bubble === undefined) {
    options.render.bubble = true;
  }

  if (options.render.autoboot === undefined) {
    options.render.autoboot = true;
  }

  if (options.render.applyLinkTarget === undefined) {
    options.render.applyLinkTarget = '_blank';
  }

  if (options.render.primaryColor === undefined) {
    options.render.primaryColor = '#2194f3';
  }

  if (options.render.primaryTextColor === undefined) {
    options.render.primaryTextColor = '#fff';
  }

  if (!options.candidate) {
    let qps = (new URL(window.document.location)).searchParams;
    let magicTokenAuth = qps.get('__fr-u__');

    if (magicTokenAuth) {
      // if no canidate is provided and we see this specific query param
      // it's an indication that a user signed in with a magic link.
      // set this QP to the candidate and it should sign them in!
      options.candidate = JSON.parse(window.atob(magicTokenAuth));
      forcePopup = true;
    }
  }

  if (options.candidate) {
    let shouldDeleteCandidateOption = false;
    if (typeof(options.candidate.email) !== 'string') {
      shouldDeleteCandidateOption = true;
      let missingEmail = new Error('Partner id: ' + partnerId + ' passed a candidate without a valid email. options.candidate.email must be a string if provided.');
      reportError(missingEmail);
    }

    if (typeof (options.candidate.emailSignature) !== 'string') {
      shouldDeleteCandidateOption = true;
      let missingSignature = new Error('Partner id: ' + partnerId + ' passed a candidate without a valid signature. options.candidate.emailSignature must be a string if provided.');
      reportError(missingSignature);
    }

    if (options.candidate.name && typeof (options.candidate.name) !== 'string') {
      delete options.candidate.name;
      let invalidName = new Error('Partner id: ' + partnerId + ' passed a candidate with an invalid name. options.candidate.name must be a string if provided.');
      reportError(invalidName);
    }

    if (options.candidate.resumeUrl && typeof (options.candidate.resumeUrl) !== 'string') {
      delete options.candidate.resumeUrl;
      let invalidResumeUrl = new Error('Partner id: ' + partnerId + ' passed a candidate with an invalid resume url. options.candidate.resumeUrl must be a string if provided.');
      reportError(invalidResumeUrl);
    }

    if (options.candidate.resumeUrl && !options.candidate.resumeUrl.startsWith('http')) {
      delete options.candidate.resumeUrl;
      let invalidResumeUrl = new Error('Partner id: ' + partnerId + ' passed a candidate with an reusme URL. options.candidate.resumeUrl must beging with "http"');
      reportError(invalidResumeUrl);
    }

    if (shouldDeleteCandidateOption) {
      delete options.candidate;
    }
  }

  if (options.callback) {
    console.error('options.callback has been renamed to options.callbacks.onMatchCreated');
    options.callbacks.onMatchCreated = options.callback;
  }

  if (options.callback === undefined) {
    options.callback = function () { }
  }

  if (options.callbacks.onMatchCreated === undefined) {
    options.callbacks.onMatchCreated = function () {};
  }

  if (options.callbacks.canBoot === undefined) {
    options.callbacks.canBoot = function () { };
  }

  if (options.callbacks.onApply === undefined) {
    options.callbacks.onApply = function () {};
  }

  if (typeof options.callbacks.onMatchCreated !== 'function') {
    options.callbacks.onApply = function() {}
    let invalidCallbackOption = new Error('Partner id: ' + partnerId + ' passed a callback value is was not a function or undefined. options.onMatchCreated must be a function if passed.');
    reportError(invalidCallbackOption);
  }

  if (typeof options.callbacks.onApply !== 'function') {
    options.callbacks.onApply = function () { }
    let invalidCallbackOption = new Error('Partner id: ' + partnerId + ' passed a callback value is was not a function or undefined. options.onApply must be a function if passed.');
    reportError(invalidCallbackOption);
  }

  function setOpen(element, isOpen) {
    if (isOpen){
      element.classList.add('opened');
      element.classList.remove('closed');
    } else {
      element.classList.add('closed');
      element.classList.remove('opened');
    }
  }

  function trackEvent(event) {
    let metadata = event.metadata || {}
    metadata.partnerId = partnerId;
    metadata.externalJobId = externalJobId;
    metadata.returningUser = returningUser;
    metadata.isMobile = isMobile();

    const accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    const memberId = accountDetails?.MemberId;

    fetch(ROOT_API + '/events', {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'events',
          attributes: {
            sourceSystem: 'LOADER',
            sourceUrl: window.location.href,
            clientUserId: sessionUUID(),
            memberId,
            talentIncKey: partnerId,
            name: event.name,
            metadata
          }
        }
      })
    }).catch(handleFetchError);
  };

  function sessionUUID() {
    if (memoryUUID) {
      returningUser = true;
      return memoryUUID;
    }

    if (typeof sessionStorage === 'undefined') {
      return;
    }

    let existingUUID = sessionStorage.getItem('fr-uuid');
    if (existingUUID) {
      memoryUUID = existingUUID;
      returningUser = true;
      return existingUUID;
    }

    let newUUID = generateUUID();
    sessionStorage.setItem('fr-uuid', newUUID);

    return newUUID;
  }

  function uuidish() {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  }

  function generateUUID() {
    return crypto.randomUUID ? crypto.randomUUID() : uuidish();
  }

  function reportError(error, extra) {
    // extra = extra || {};
    // extra.url = window.location.toString();
    // extra.source = 'LOADER';
    // console.error(error);
    // fetch(ROOT_API + '/errors', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     message: error.message,
    //     stack: error.stack,
    //     extra
    //   })
    // }).catch(handleFetchError);
  }

  function isMobile() {
    return window.matchMedia('(max-width: 767px)').matches;
  }

  function disablePopup() {
    if (typeof sessionStorage === 'undefined') {
      return;
    }

    sessionStorage.setItem('fr-popup', true);
  }

  function canPopup() {
    if (isMobile()) {
      // automatic popup on mobile is
      // an undesirable experience.
      return false;
    }

    // https://linear.app/resume-io/issue/TC-235/auto-pop-widget-for-all-jobs
    // this is wildly user-hostile but we're doing it to bump
    // conversion. Keeping this in place because I suspect we'll
    // revert after complaints.
    // if (typeof sessionStorage === 'undefined') {
    //   return true;
    // }

    // let popup = sessionStorage.getItem('fr-popup');
    // if (popup) {
    //   // already popped up once this session
    //   return false;
    // }

    // disablePopup();
    return true;
  }

  function handleFetchError(error) {
    let log = false;
    if (error.message === 'Failed to fetch') {
      log = true;
    }

    reportError(error, { log });
  }

  function createWidgetContainerIFrame(jobId) {
    frIframe = document.createElement('iframe');
    frIframe.setAttribute('id', 'fr-iframe');
    frIframe.setAttribute('data-test-widget-open', false);

    frIframe.sandbox = 'allow-popups allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation';
    let src;
    if (IS_TEST) {
      src = 'http://localhost:1234/support/widget.html' + '?widgetType=' + widgetType;
    } else {
      src = ROOT_WIDGET + '/jobs/' + jobId + '/' + widgetType + '?widgetType=' + widgetType;
    }
    
    src = src + '&sourceUrl=' + window.btoa(window.location.href);

    if (sessionUUID()) {
      let encodedClientId = window.btoa(sessionUUID());
      src = src + '&clientUserId=' + encodedClientId;
    }

    const accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    const memberId = accountDetails?.MemberId;

    if (memberId) {
      const encodedMemberId = window.btoa(memberId);
      src = src + '&memberId=' + encodedMemberId;
    }

    if (options.meta) {
      let encodedMeta = window.btoa(JSON.stringify(options.meta));
      src = src + '&meta=' + encodedMeta;
    }

    if (options.candidate) {
      options.candidate.partnerId = partnerId;
      let encodedCandidate = window.btoa(JSON.stringify(options.candidate));
      src = src + '&candidate=' + encodedCandidate;
    }

    let themeData = {
      partnerId: partnerId,
      isMobile: isMobile(),
      primaryColor: options.render.primaryColor,
      primaryTextColor: options.render.primaryTextColor,
      applyLinkTarget: options.render.applyLinkTarget
    }

    if (options.render.termsAndConditionsMarkup) {
      themeData.termsAndConditionsMarkup = window.encodeURIComponent(options.render.termsAndConditionsMarkup);
    }

    if (options.render.applyUrl !== undefined) {
      themeData.applyUrl = options.render.applyUrl;
    }

    let encodedTheme = window.btoa(JSON.stringify(themeData));
  
    src = src + '&theme=' + encodedTheme;

    frIframe.src = src;
    return frIframe;
  }

  function createButton() {
    FAB = document.createElement('button');
    FAB.type = 'button';
    FAB.id = 'fr-fab';
    FAB.setAttribute('data-test-button', true);
    FAB.style.backgroundColor = options.render.primaryColor;

    let fabText = document.createElement('span');
    fabText.setAttribute('id', 'fr-fab-text');

    if (options.render.bubbleText) {
      fabText.innerHTML = options.render.bubbleText;
    } else {
      fabText.innerHTML = 'Compare ' + terminology + ' With This Job';
    }

    FAB.appendChild(fabText);
    return FAB;
  }

  function setOpenButtonAttributes(bubble) {
    bubble.title = 'Compare your resume to this job.';
    return bubble;
  }

  function setCloserButtonAttributes(bubble) {
    bubble.title = 'Close';
    return bubble;
  }

  function setupBubble() {
    frInnerContainer = document.createElement('div');
    frInnerContainer.setAttribute('id', 'fr-inner-container');
    frInnerContainer.setAttribute('data-test-container', true);

    frInnerContainer.classList.add('features--' + widgetType);
    frInnerContainer.classList.add('partner--' + partnerId);

    setOpen(frInnerContainer, false);

    let floatingActionButton = createButton();
    listenForClick(floatingActionButton, window.frToggleVisibility);
    
    if (!options.render.bubble) {
      frInnerContainer.classList.add('features--no-bubble')
    }
    
    frInnerContainer.appendChild(frIframe);
    frInnerContainer.appendChild(floatingActionButton);
    
    setOpenButtonAttributes(floatingActionButton);

    return frInnerContainer;
  }

  function listenForClick(element, fn) {
    element.addEventListener('click', fn);
  }

  function shutdown() {
    window.removeEventListener('message', onPostMessage);
    window.removeEventListener('DOMContentLoaded', bootEvent);

    if (frInnerContainer) {
      frInnerContainer.remove();
    }
  }
  
  function onApplyCreatedHandler(payload) {
    let { response, meta } = payload;
    let user = {
      email: response.email
    };

    let job = {
      externalId: response.externalId
    };

    options.callbacks.onApply(user, job, meta);
  };

  function onCloseButtonClicked() {
    window.frSetVisibility(false);
  }

  function onMatchCreatedHandler(payload) {
    let { response, meta } = payload;

    let candidate = response.included?.find(r => r.type === 'users');
    let resume = response.included?.find(r => r.type === 'resumes');

    let trimmedMatch = response.data.attributes;
    delete trimmedMatch.jobId;
    delete trimmedMatch.resumeId;
    delete trimmedMatch.meta;
    
    let { email, name, phoneNumber } = candidate?.attributes;
    
    let locationEvidence = trimmedMatch.keyInfoEvidencePositive.find(k => k.name === 'Location');
    let location = locationEvidence?.value;
    if (!location) {
      locationEvidence = trimmedMatch.keyInfoEvidenceNegative.find(k => k.name === 'Location');
      location = locationEvidence?.value;
    }

    let trimmedCandidate = {
      email,
      name,
      resumeUrl: resume?.attributes?.url,
      phoneNumber,
      location
    };

    options.callbacks.onMatchCreated(trimmedCandidate, trimmedMatch, meta);
  }

  window.frSetVisibility = function (value) {
    trackEvent({
      name: value ? 'widegt:toggle:open' : 'widget:toggle:close',
      metadata: {
        from: !value,
        to: value
      }
    });

    // once manuually triggered prevent automatic triggering.
    disablePopup();

    // ensure we wait for job id resultion before this happens
    return resolveJobIdPromise.then(function () {
      isiFrameExpanded = value;
      frIframe.setAttribute('data-test-widget-open', isiFrameExpanded)
      setOpen(frInnerContainer, isiFrameExpanded);
      if (isiFrameExpanded) {
        setCloserButtonAttributes(FAB);
      } else {
        setOpenButtonAttributes(FAB);
      }
    }).catch(handleFetchError);
  };

  window.frToggleVisibility = function () {
    return window.frSetVisibility(!isiFrameExpanded);
  };

  function resolveJobId(partnerId, externalJobId) {
    let path = '/jobs/external_job?externalJobId=' + externalJobId + '&partnerId=' + partnerId + '&version=' + VERSION + '&widget=1'

    return fetch(ROOT_API + path).then(function (response) {
      if (response.status === 404) {
        return null;
      }
      
      if (response.status === 400) {
        console.error('Cannot continue. Partner is using an outdated loader script, possibly bundled into their app or agressively cached.');
      }

      return response.json().then(function (data) {
        if (data.terminology === 'CV') {
          terminology = 'CV';
        }

        return data.jobId;
      });
    }).catch(handleFetchError);
  }

  let resolveJobIdPromise = resolveJobId(partnerId, externalJobId).then(function(jobId) {
    if (jobId) {
      try {
        options.callbacks.canBoot();
      } catch(error) {
        // error in partner callback code. Just ignore.
      }
    }

    return jobId;
  });

  function boot() {
    sessionUUID();

    return resolveJobIdPromise.then(function (jobId) {
      if (!jobId) {
        console.error('Job referenced by partnerId ' + partnerId + ' and externalJobId ' + externalJobId + ' was not found');
        return;
      }

      trackEvent({
        name: 'loaded',
        metadata: {
          optionsUsed
        }
      });

      createWidgetContainerIFrame(jobId);
      let FABContainer = setupBubble();
      options.render.container.shadowRoot.appendChild(FABContainer);

      if (forcePopup) {
        window.frSetVisibility(true);
      }

      if (options.render.popupTiming !== false) {
        setTimeout(function () {
          if (canPopup()) {
            window.frSetVisibility(true);
          }
        }, options.render.popupTiming);
      }

    }).catch(function (error) {
      handleFetchError(error);
      shutdown();
    });
  }

  function bootEvent() {
    if (options.render.autoboot) {
      boot();
    }
  }

  function onPostMessage(event) {
    if (event.data && event.data.source === 'talentinc') {
      switch (event.data.name) {
        case 'close-button:clicked':
          onCloseButtonClicked(event.data);
          break;
        case 'match:created':
          onMatchCreatedHandler(event.data);
          break;
        case 'application:created':
          onApplyCreatedHandler(event.data);
          break;
        default:
          reportError( new Error('Loader for widget received postMessage with name "' + event.data.name + '" but there is no handle for it.'))
      }
    }
  }

  window.addEventListener('message', onPostMessage);
  window.addEventListener('DOMContentLoaded', bootEvent);

  let result = {
    boot,
    shutdown
  };
  
  if (IS_TEST) {
    result.parseCallbackPayload = parseCallbackPayload;
  }
  
  return result;
};
